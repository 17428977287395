import React, { memo } from 'react'
import { useDispatch } from 'react-redux'
import { Sun, Thunder, Warning } from '../../assets'
import { livePrompt } from '../../redux/messages'
import './style.scss'

const New = memo(() => {
  const dispatch = useDispatch()
  return (
    <div className='New'>
      <div>
        <h1 className='title currentColor'>Cyber Quick: Your Cybersecurity Companion</h1>
        <p className='currentColor'>Welcome to Cyber Quick, your go-to source for all things cybersecurity. Whether you're a seasoned professional or just getting started, Cyber Quick is here to assist you with accurate and timely information to keep you and your digital assets secure.</p>
      </div>
    </div>
  )
})

export default New