import axios from "axios";

const API_URL = "https://bot.cyberquick.ai/api"; //AWS backend
// const API_URL = "http://localhost:5222";
const instance = axios.create({
    withCredentials: true,
})
instance.interceptors.request.use(function (config) {
    config.url = `${API_URL}${config.url}`;
    const token = localStorage.getItem("token");
    config.params = { ...config.params,token};
    return config;
}, function (error) {
    return Promise.reject(error);
});

export default instance