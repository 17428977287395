// category.jsx

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Form } from 'react-bootstrap';
import { setLoading } from "../redux/loading";
import 'bootstrap/dist/css/bootstrap.min.css';

const Category = () => {
  const [categories, setCategories] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [categoryData, setCategoryData] = useState({ title: '', description: '' });
  const [editCategoryData, setEditCategoryData] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    fetchData();
    setTimeout(() => {
      dispatch(setLoading(false));
    }, 1000);
  }, []);

  const fetchData = () => {
    axios.get('/api/category')
      .then(res => setCategories(res.data))
      .catch(err => console.error(err));
  };

  const handleAddModal = () => setShowAddModal(!showAddModal);
  const handleEditModal = () => setShowEditModal(!showEditModal);
  const handleDeleteModal = () => setShowDeleteModal(!showDeleteModal);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setCategoryData({ ...categoryData, [name]: value });
  };

  const handleEditInputChange = e => {
    const { name, value } = e.target;
    setEditCategoryData({ ...editCategoryData, [name]: value });
  };

  const handleAddCategory = () => {
    axios.post('/api/category', categoryData)
      .then(() => {
        fetchData();
        handleAddModal();
      })
      .catch(err => console.error(err));
  };

  const handleEditCategory = () => {
    axios.put(`/api/category/${editCategoryData._id}`, editCategoryData)
      .then(() => {
        fetchData();
        handleEditModal();
      })
      .catch(err => console.error(err));
  };

  const handleDeleteCategory = () => {
    axios.delete(`/api/category/${editCategoryData._id}`)
      .then(() => {
        fetchData();
        handleDeleteModal();
      })
      .catch(err => console.error(err));
  };

  return (
    <div>
      <h1>Categories</h1>
      <Button variant="primary" onClick={handleAddModal}>Add category</Button>
      <table className="table mt-3">
        <thead>
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th>Description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {categories.map(category => (
            <tr key={category._id}>
              <td>{category._id}</td>
              <td>{category.title}</td>
              <td>{category.description}</td>
              <td>
                <Button variant="info" onClick={() => { setShowEditModal(true); setEditCategoryData(category); }}>Edit</Button>{' '}
                <Button variant="danger" onClick={() => { setShowDeleteModal(true); setEditCategoryData(category); }}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Add category Modal */}
      <Modal show={showAddModal} onHide={handleAddModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="title">
              <Form.Label>Title</Form.Label>
              <Form.Control type="text" placeholder="Enter title" name="title" value={categoryData.title} onChange={handleInputChange} />
            </Form.Group>
            <Form.Group controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control type="text" placeholder="Enter description" name="description" value={categoryData.description} onChange={handleInputChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleAddModal}>Close</Button>
          <Button variant="primary" onClick={handleAddCategory}>Add</Button>
        </Modal.Footer>
      </Modal>

      {/* Edit category Modal */}
      <Modal show={showEditModal} onHide={handleEditModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="title">
              <Form.Label>Title</Form.Label>
              <Form.Control type="text" placeholder="Enter title" name="title" value={editCategoryData.title} onChange={handleEditInputChange} />
            </Form.Group>
            <Form.Group controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control type="text" placeholder="Enter description" name="description" value={editCategoryData.description} onChange={handleEditInputChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditModal}>Close</Button>
          <Button variant="primary" onClick={handleEditCategory}>Save Changes</Button>
        </Modal.Footer>
      </Modal>

      {/* Delete category Modal */}
      <Modal show={showDeleteModal} onHide={handleDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete category</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this category?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteModal}>Close</Button>
          <Button variant="danger" onClick={handleDeleteCategory}>Delete</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Category;
